@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

/* General Mobile Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

/* Remove default list styles */
ul {
  list-style: none;
}

/* Navigation Styling */
nav {
  z-index: 1;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 30px 50px;
  background-color: white;
}

a {
  text-decoration: none;
  color: #000;
}

.nav-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Contact button styling */
.contact {
  font-size: 0.9rem;
  background: #facf0f;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  border: 1px solid #facf0f;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
  transition: transform 0.3s;
  padding: 8px 20px;
  border-radius: 5px;
}

.contact:hover {
  transform: translateY(-5px);
}

.logo {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}

.khanal {
  color: #facf10;
}

/* Hero Section */
.hero {
  display: block;
  height: 90vh;
  position: relative;
  background: linear-gradient(
      to right,
      rgba(245, 245, 245, 0.8),
      rgba(245, 245, 245, 0.8)
    ),
    url(https://d33wubrfki0l68.cloudfront.net/daac8858fd4a0ccea44f59dfd079c3c16c263f33/c157c/assets/svg/common-bg.svg);
}

.hero-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80rem;
  width: 82%;
  text-align: center;
  margin: 0 auto;
}

.intro h1 {
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #111;
  font-weight: 800;
}

.about {
  text-align: center;
  width: 60%;
  margin: 3rem auto;
}

.about p {
  color: #333;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}

.projects {
  margin-top: 3rem;
  text-align: center;
}

/* Buttons styling */
.project-in,
.cv-button {
  display: inline-block;
  margin: 0 10px;
  border: none;
  background-color: #facf0f;
  color: #333333;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 16px 40px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
  transition: transform 0.3s;
  transform: translateY(-5px);
}

.project-in:hover,
.cv-button:hover {
  background-color: #ffc007;
}

.contact-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.contact-btn {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
}

.icon-btn:hover {
  transform: translateY(-5px);
  scale: 1.1;
  transition: transform 0.5s;
}

.contact-me {
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #111;
  font-weight: 800;
  text-align: center;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  /* Adjust hero section for mobile */
  .hero-contents {
    width: 100%;
    text-align: center;
  }

  .intro h1 {
    font-size: 2rem;
    letter-spacing: 2px;
  }

  .about {
    margin: 2rem auto;
  }

  .about p {
    font-size: 1rem;
  }

  .projects {
    margin-top: 2rem;
  }

  .project-in,
  .cv-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  /* Adjust navigation padding */
  nav {
    padding: 15px 20px;
  }

  .logo {
    font-size: 16px;
  }

  .contact {
    padding: 6px 15px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  /* Smaller mobile devices */
  .hero {
    height: 80vh;
  }

  .intro h1 {
    font-size: 2rem;
  }
  .about {
    width: 80%;
  }
  .about p {

    font-size: 0.9rem;
  }

  .projects {
    margin-top: 1.5rem;
  }

  .project-in,
  .cv-button {
    padding: 8px 16px;
    font-size: 0.8rem;
  }

  .contact {
    padding: 5px 10px;
    font-size: 0.75rem;
  }
}
