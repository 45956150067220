/* CVPage.css */

.cv-container {
    padding: 50px;
    background-color: #f9f9f9;  /* Light grey background to match your theme */
    text-align: center;
  }
  
  .cv-header {
    text-align: left;
    margin-bottom: 30px;
  }
  
  .cv-portfolio {
    font-size: 1.2rem;
    font-weight: 400;
    color: #888;  /* Grey text for smaller 'CV' */
    display: block;
  }
  
  .cv-title {
    font-size: 3rem;
    font-weight: bold;
    color: #000;  /* Black text for 'My CV' */
    display: inline-block;
    margin: 0;
    padding-right: 10px;
    position: relative;
  }
  
  .cv-title::before {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #FFC107;  /* Yellow line above the text */
    position: absolute;
    top: -5px;  /* Position the line above the text */
    left: 0;
  }
  
  iframe {
    border: none;
    width: 100%;
    height: 1000px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Subtle shadow for the iframe */
  }
  