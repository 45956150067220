.section-line {
    width: 100px; 
    height: 3px;   
    background-color: #FFC107; 
    margin: 0 auto 10px; 
  }
.portfolio-container {
    padding: 50px;
    background-color: white;
    text-align: center;
  }
  
  .portfolio-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px; 
  }
  
  /* Filter Bar */
  .filter-bar {
    display: flex;
    justify-content: center;
    gap: 20px; 
    margin-bottom: 40px;
  }
  
  .filter-bar button {
    padding: 12px 30px; 
    margin: 0;
    border: none;
    background-color: #FFC107;
    color: white;
    cursor: pointer;
    border-radius: 50px; 
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;  
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .filter-bar button.active {
    background-color: #ff9800;
    transform: scale(1.1);  /* Slight enlargement for active button */
  }
  
  .filter-bar button:hover {
    background-color: #ff9800;
    transform: scale(1.05);  /* Slight enlargement on hover */
  }
  
  /* Grid Layout */
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* Set to 3 columns */
    gap: 20px;
    justify-content: center;
  }
  
  .project-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-info {
    padding: 15px;
  }
  
  .project-title {
    font-size: 1.5rem;
    color: #000;
    margin: 10px 0;
  }
  
  .project-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .project-link {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #FFC107;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .project-link:hover {
    background-color: #ff9800;
  }
  
  
  @media (max-width: 1024px) {
    .projects-grid {
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media (max-width: 768px) {
    .portfolio-title {
        font-size: 2.5rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 20px; 
      }
    .projects-grid {
      grid-template-columns: 1fr;  
    }
    .project-title {
        font-size: 1.5rem;
        color: #000;
        margin: 10px 0;
      }

      .filter-bar button {
        padding: 8px 20px; 
        margin: 0;
        border: none;
        background-color: #FFC107;
        color: white;
        cursor: pointer;
        border-radius: 50px; 
        font-size: .8rem;
        font-weight: bold;
        text-transform: uppercase;  
        transition: transform 0.3s ease, background-color 0.3s ease;
      }
  }
  